import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class FacultyService {
  constructor(public http: HttpClient, public okta: OktaService) {}

  public listFaculties(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
      query{
        listFaculties {items { id title content category{ id name } }}
      }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public addFaculty(facultyModel: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          createFaculty(input: {title:"${
            facultyModel.title.value
          }", category_id:"${
        facultyModel.categoryId.value
      }", content:"${encodeURI(facultyModel.content.value)}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteFaculty(id: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          deleteFaculty(input:{id:"${id}"}) {id faqs {
            items {
              id
            }
          }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getFaculty(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          getFaculty(id:"${id}") { id title content category{ id name }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateFaculty(id: string, facultyModel: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          updateFaculty(input: {id:"${id}", title:"${
        facultyModel.title.value
      }", category_id:"${facultyModel.categoryId.value}", content:"${encodeURI(
        facultyModel.content.value
      )}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
