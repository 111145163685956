import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public userRole!: string;
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate() {
    await this.authService.hasRoleAdmin();
    if (localStorage.getItem('USER_ROLE') === 'ADMIN') {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
