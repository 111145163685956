<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button fxShow.sm="true" fxShow.xs="true" fxShow="false">
    <mat-icon matTooltip="Menu" (click)="clickMenu()">menu</mat-icon>
  </button>
  <div class="mr-0 ml-auto">
    <button mat-icon-button>
      <mat-icon matTooltip="Déconnexion" (click)="okta.logout()"
        >exit_to_app</mat-icon
      >
    </button>
  </div>
</mat-toolbar>
