import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HomepageClientService } from '../shared/services/homepage-client.service';
import { NotifyService } from '../shared/services/notify.service';

@Component({
  selector: 'app-homepage-client',
  templateUrl: './homepage-client.component.html',
  styleUrls: ['./homepage-client.component.scss'],
})
export class HomepageClientComponent implements OnInit {
  public homepageClientTextsForm!: FormGroup;
  public modules = {};
  public isSubmitted: boolean = false;
  public homepageClientTextsId!: string;

  constructor(
    private homepageClientService: HomepageClientService,
    private notifyService: NotifyService
  ) {
    this.modules = {
      toolbar: [['bold', 'italic', 'underline', 'strike']],
    };
    this.homepageClientTextsForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.homepageClientService.listHomepageTexts().subscribe(
      (response) => {
        if (response.data && response.data.listHomepageTexts) {
          this.homepageClientTextsFormControls.primaryText.setValue(
            response.data.listHomepageTexts.items[0].primary
          );
          this.homepageClientTextsFormControls.facultiesText.setValue(
            response.data.listHomepageTexts.items[0].facultiesText
          );
          this.homepageClientTextsId =
            response.data.listHomepageTexts.items[0].id;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      primaryText: new FormControl('', Validators.required),
      facultiesText: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.homepageClientTextsForm.valid) {
      const data = {
        primaryText: this.homepageClientTextsFormControls.primaryText.value,
        facultiesText: this.homepageClientTextsFormControls.facultiesText.value,
      };
      if (this.homepageClientTextsId) {
        this.homepageClientService
          .updateHomepageTexts(this.homepageClientTextsId, data)
          .subscribe(
            (res) => {
              this.notifyService.emit('SUCCESS');
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.homepageClientService.createHomepageTexts(data).subscribe(
          (res) => {
            if (res.data && res.data.createHomepageTexts.id) {
              this.homepageClientTextsId = res.data.createHomepageTexts.id;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  get homepageClientTextsFormControls() {
    return this.homepageClientTextsForm.controls;
  }
}
