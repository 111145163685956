import { CallbackComponent } from './callback/callback.component';
import { HomepageClientComponent } from './homepage-client/homepage-client.component';
import { QuestionResponseComponent } from './question-response/question-response.component';
import { CreateFacultyComponent } from './faculties/create-faculty/create-faculty.component';
import { Injector, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CategoriesComponent } from './categories/categories.component';
import { FacultiesComponent } from './faculties/faculties.component';
import { HomeComponent } from './home/home.component';
import { CreateComponent } from './users/create/create.component';
import { UsersComponent } from './users/users.component';
import { AddQuestionResponseComponent } from './question-response/add-question-response/add-question-response.component';
import { OktaOauthGuard } from './helpers/okta-oauth.guard';
import { AuthGuard } from './helpers/auth.guard';

export function onAuthRequired(injector: Injector) {
  const router = injector.get(Router);

  // Redirect the user to your custom login page
  router.navigate(['/login']);
}

const routes: Routes = [
  {
    path: 'login/callback',
    component: CallbackComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [OktaOauthGuard],
    children: [
      {
        path: 'thematics',
        children: [{ path: '', component: CategoriesComponent }],
        canActivate: [OktaOauthGuard, AuthGuard],
        data: {
          role: 'ADMIN',
        },
      },
      {
        path: 'homepage/client',
        component: HomepageClientComponent,
        canActivate: [OktaOauthGuard, AuthGuard],
        data: {
          role: 'ADMIN',
        },
      },
      {
        path: 'users',
        children: [
          { path: '', component: UsersComponent },
          { path: 'new', component: CreateComponent },
          { path: 'update/:id', component: CreateComponent },
        ],
        canActivate: [OktaOauthGuard, AuthGuard],
        data: {
          role: 'ADMIN',
        },
      },
      {
        path: 'faculties',
        children: [
          { path: '', component: FacultiesComponent },
          { path: 'new', component: CreateFacultyComponent },
          { path: 'update/:id', component: CreateFacultyComponent },
        ],
        canActivate: [OktaOauthGuard, AuthGuard],
        data: {
          role: 'ADMIN',
        },
      },
      {
        path: 'questions/responses',
        children: [
          { path: '', component: QuestionResponseComponent },
          { path: 'new', component: AddQuestionResponseComponent },
          { path: 'update/:id', component: AddQuestionResponseComponent },
        ],
        canActivate: [OktaOauthGuard, AuthGuard],
        data: {
          role: 'ADMIN',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
