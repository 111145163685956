import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionResponseService {
  constructor(public http: HttpClient, public okta: OktaService) {}

  public listFAQS(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listFAQS { 
            items { 
              id 
              question 
              response 
              faculties { 
                items { 
                  id
                  faculty { 
                    id 
                    title 
                  } 
                } 
              }
              categories { 
                items { 
                  id
                  category { 
                    id 
                    name 
                  } 
                } 
              } 
            } 
          }
        }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public addFAQ(qaModel: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          createFAQ(input: {question:"${
            qaModel.question
          }", response:"${encodeURI(qaModel.response)}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteFAQ(id: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          deleteFAQ(input:{id:"${id}"}) {id faculties {items{id faculty{id}}} categories {items{id category{id}}}}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateFAQ(id: string, qaModel: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          updateFAQ(input: {id:"${id}", question:"${
        qaModel.question
      }", response:"${encodeURI(qaModel.response)}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public getFAQ(id: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          getFAQ(id:"${id}"){
            id question response faculties {items{id faculty{id}}} categories {items{id category{id}}}
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
