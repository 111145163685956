<div class="w-full h-full px-16 py-16">
  <div class="w-full block md:flex justify-center items-center my-8">
    <label class="text-lg text-blue-400" for="title">Titre: </label>
    <input
      placeholder="Titre ..."
      class="
        w-full
        lg:w-1/3
        h-8
        border-2
        rounded-lg
        outline-none
        px-2
        text-md
        md:ml-4
        mt-4
        md:mt-0
      "
      [ngClass]="faculty.title.hasError ? 'border-red-300' : 'border-blue-300'"
      [(ngModel)]="faculty.title.value"
      (input)="clearTitleError()"
    />
  </div>
  <div class="w-full block md:flex justify-center items-center my-8">
    <label class="text-lg text-blue-400" for="title">Thématique:</label>
    <mat-select
      (selectionChange)="onChange($event)"
      [(value)]="faculty.categoryId.value"
      class="
        w-full
        lg:w-1/3
        h-8
        border-2
        rounded-lg
        outline-none
        px-2
        text-md
        pt-1
        md:ml-4
        mt-4
        md:mt-0
        bg-white
      "
      [ngClass]="
        faculty.categoryId.hasError ? 'border-red-300' : 'border-blue-300'
      "
    >
      <mat-option *ngFor="let category of categories" [value]="category.id">{{
        category.name
      }}</mat-option>
    </mat-select>
  </div>
  <quill-editor
    placeholder="Editer le contenu"
    [(ngModel)]="faculty.content.value"
    [modules]="modules"
  ></quill-editor>
  <div
    class="w-full md:w-1/2 xl:w-1/4 mx-auto my-8 flex justify-center xl:block"
  >
    <button
      class="
        bg-blue-300
        hover:bg-blue-400
        text-white
        font-bold
        py-2
        px-4
        rounded
        w-full
        text-center
      "
      (click)="onSave()"
    >
      Sauvegarder
    </button>
  </div>
</div>
