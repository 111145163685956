import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from '../shared/components/dialogs/delete-dialog/delete-dialog.component';
import { FacultyService } from '../shared/services/faculty.service';
import { FaqFacultyService } from '../shared/services/faq-faculty.service';
export interface FacultyElement {
  id: string;
  title: string;
  content: string;
  category: {
    id: string;
    name: string;
  };
}

let ELEMENT_DATA: FacultyElement[] = [];

@Component({
  selector: 'app-faculties',
  templateUrl: './faculties.component.html',
  styleUrls: ['./faculties.component.scss'],
})
export class FacultiesComponent implements OnInit {
  public displayedColumns: string[] = ['title', 'category', 'update', 'delete'];
  public dataSource = new MatTableDataSource<FacultyElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public currentFaculty!: FacultyElement;

  constructor(
    public dialog: MatDialog,
    public facultyService: FacultyService,
    public faqFacultyService: FaqFacultyService,
    public router: Router
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getFaculties();
  }

  getFaculties(): void {
    this.facultyService.listFaculties().subscribe(
      (res) => {
        if (res.data && res.data.listFaculties) {
          ELEMENT_DATA = [];
          res.data.listFaculties.items.forEach((element: any) => {
            ELEMENT_DATA.push(element);
          });
          this.dataSource = new MatTableDataSource<FacultyElement>(
            ELEMENT_DATA
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openOkCancelDialog(id: string) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          text: 'Voulez vous vraiment supprimer cete filière !',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data === 'YES') {
          this.facultyService.deleteFaculty(id).subscribe(
            (res) => {
              if (res.data && res.data.deleteFaculty.id) {
                res.data.deleteFaculty.faqs.items.forEach(
                  (faqFaculty: { id: string }) => {
                    this.faqFacultyService
                      .deleteFAQFaculty(faqFaculty.id)
                      .subscribe(
                        (res2) => {
                          console.log(res2);
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  }
                );
                this.getFaculties();
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      });
  }

  public doFilter = ($event: Event) => {
    this.dataSource.filter = ($event.target as HTMLInputElement).value
      .trim()
      .toLocaleLowerCase();
  };

  redirectToCreate(element?: FacultyElement) {
    if (element) {
      this.router.navigate(['faculties/update/', element.id]);
    } else {
      this.router.navigate(['faculties/new']);
    }
  }
}
