import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NotificationComponent } from '../shared/components/dialogs/notification/notification.component';
import { AuthService } from '../shared/services/auth.service';
import { NotifyService } from '../shared/services/notify.service';
import { SidenavService } from '../shared/services/sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('sidenav', { static: true })
  public sidenav!: MatSidenav;
  public isLargeScreen: boolean = false;
  public isAdmin: boolean = false;
  public showSuccessNotification: boolean = false;
  public noAccessMessage: string = '';
  constructor(
    public router: Router,
    private sideNavService: SidenavService,
    private authService: AuthService,
    private notifyService: NotifyService,
    public dialog: MatDialog
  ) {
    this.notifyService.notify$.subscribe((value) => {
      if (value === 'SUCCESS') {
        this.dialog.open(NotificationComponent, {
          height: '10rem',
          width: '20rem',
        });
      }
    });
  }

  ngOnInit() {
    this.sideNavService.sideNavToggleSubject.subscribe(() => {
      this.sidenav.toggle();
    });
    if (window.screen.width >= 1024) {
      this.isLargeScreen = true;
      this.sidenav.open;
    } else {
      this.isLargeScreen = false;
      this.sidenav.close;
    }
    this.hasAdminRole();
  }

  async hasAdminRole() {
    await this.authService.hasRoleAdmin();
    if (localStorage.getItem('USER_ROLE') === 'ADMIN') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
      this.noAccessMessage = "Vous n'êtes pas enregistré dans l'application";
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    if (event.target.innerWidth >= 1024) {
      this.isLargeScreen = true;
    } else {
      this.isLargeScreen = false;
    }
  }
}
