import { Component, OnInit } from '@angular/core';
import { OktaService } from '../shared/services/okta.service';

@Component({
  template: '',
})
export class CallbackComponent implements OnInit {
  constructor(private okta: OktaService) {}

  ngOnInit() {
    try {
      this.okta.handleAuthentication()
    } catch (error) {
      alert(
        "vous n'avez pas accès à cette application, veuillez contacter votre administrateur okta"
      );
    }
  }
}
