import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class FaqFacultyService {
  constructor(public http: HttpClient, public okta: OktaService) {}

  public addFAQFaculty(faqId: string, facultyId: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
      mutation{
        createFAQFaculty(input:{fAQFacultyFaqId:"${faqId}", fAQFacultyFacultyId:"${facultyId}"}){id}
      }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteFAQFaculty(id: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
      mutation{
        deleteFAQFaculty(input:{id:"${id}"}){id}
      }
    `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
