<div class="w-full h-full px-16 py-16">
  <div class="block sm:flex justify-center items-center">
    <p class="text-blue-400 text-lg m-0">Recherche:</p>
    <input
      placeholder="Tapez ici pour rechercher..."
      type="text"
      class="w-64 h-8 px-2 text-lg rounded-md outline-none sm:mx-4"
      (keyup)="doFilter($event)"
      placeholder=""
    />
  </div>
  <div class="mat-elevation-z8 my-8">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="users">
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
        <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
      </ng-container>
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prénom</th>
        <td mat-cell *matCellDef="let element">{{ element.first_name }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>
      <ng-container matColumnDef="gid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>GID</th>
        <td mat-cell *matCellDef="let element">{{ element.gid }}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rôle</th>
        <td mat-cell *matCellDef="let element">{{ element.role.label }}</td>
      </ng-container>
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          Mettre à jour
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="primary"
            (click)="redirectToCreate(element)"
          >
            <mat-icon class="mat-18">system_update</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="text-center">Supprimer</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="warn"
            (click)="openOkCancelDialog(element.id)"
          >
            <mat-icon class="mat-18">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Selectionner une page"
    >
    </mat-paginator>
  </div>
  <button mat-icon-button color="primary">
    <mat-icon (click)="redirectToCreate()" class="mat-18">person_add</mat-icon>
  </button>
</div>
