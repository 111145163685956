<div class="w-full h-full px-16 py-16">
  <form
    [formGroup]="homepageClientTextsForm"
    (ngSubmit)="onSubmit()"
    novalidate
  >
    <div class="w-full xl:w-1/2 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="primaryText">Texte de présentation: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <quill-editor
          class="w-full"
          placeholder="Editer le contenu"
          formControlName="primaryText"
          [modules]="modules"
        ></quill-editor>
      </div>
    </div>
    <div class="w-full xl:w-1/2 md:mx-auto my-2 xl:mb-0 h-16">
      <p
        *ngIf="
          isSubmitted &&
          homepageClientTextsFormControls.primaryText.errors?.required
        "
        class="text-red-500 text-xs italic"
      >
        La champ est requis.
      </p>
    </div>
    <div class="w-full xl:w-1/2 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="facultiesText">Texte de présentation des filières: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <quill-editor
          class="w-full"
          placeholder="Editer le contenu"
          formControlName="facultiesText"
          [modules]="modules"
        ></quill-editor>
      </div>
    </div>
    <div class="w-full xl:w-1/2 md:mx-auto my-2 xl:mb-0 h-16">
      <p
        *ngIf="
          isSubmitted &&
          homepageClientTextsFormControls.facultiesText.errors?.required
        "
        class="text-red-500 text-xs italic"
      >
        La champ est requis.
      </p>
    </div>

    <div class="w-1/2 mx-auto my-8 flex justify-center xl:block">
      <button
        class="
          bg-blue-300
          hover:bg-blue-400
          text-white
          font-bold
          py-2
          px-4
          rounded
          inline-flex
          items-center
        "
        type="submit"
      >
        Sauvegarder
      </button>
    </div>
  </form>
</div>
