import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  public notify$: EventEmitter<string>;
  constructor() {
    this.notify$ = new EventEmitter();
  }

  public emit(value: string): void {
    this.notify$.emit(value);
  }
}
