<div class="w-full h-full px-16 py-16">
  <div class="block sm:flex justify-center items-center">
    <p class="text-blue-400 text-lg m-0">Recherche:</p>
    <input
      placeholder="Tapez ici pour rechercher..."
      type="text"
      class="w-64 h-8 px-2 text-lg rounded-md outline-none sm:mx-4"
      (keyup)="doFilter($event)"
      placeholder=""
    />
  </div>
  <div class="mat-elevation-z8 my-8">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="categories">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          Mettre à jour
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="primary"
            (click)="openUpdateCategoryModal(element)"
          >
            <mat-icon class="mat-18">system_update</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="text-center">Supprimer</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="warn"
            (click)="openOkCancelDialog(element.id)"
          >
            <mat-icon class="mat-18">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Selectionner une page"
    >
    </mat-paginator>
  </div>
  <div class="block sm:flex justify-left items-center">
    <p class="text-blue-400 text-lg m-0">Ajouter :</p>
    <input
      placeholder="Nom de la catégorie"
      type="text"
      [(ngModel)]="categoryName"
      class="w-64 h-8 px-2 text-lg rounded-md outline-none sm:mx-4 my-4 sm:my-0"
    />
    <button
      (click)="addCategory()"
      class="
        bg-blue-300
        hover:bg-blue-400
        text-white
        font-bold
        py-2
        px-4
        rounded
        inline-flex
        items-center
      "
    >
      <span>Sauvegarder</span>
    </button>
  </div>
</div>
