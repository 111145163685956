import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from '../shared/components/dialogs/delete-dialog/delete-dialog.component';
import { UserService } from '../shared/services/user.service';

export interface UserElement {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  gid: string;
  role: {
    id: string;
    role: string;
    label: string;
  };
}

let ELEMENT_DATA: UserElement[] = [];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = [
    'first_name',
    'last_name',
    'email',
    'gid',
    'role',
    'update',
    'delete',
  ];
  public dataSource = new MatTableDataSource<UserElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    public router: Router
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.userService.listUsers().subscribe(
      (res) => {
        if (res.data && res.data.listUsers) {
          ELEMENT_DATA = [];
          res.data.listUsers.items.forEach((element: any) => {
            switch (element.role.role) {
              case 'ADMIN':
                element.role.label = 'Administrateur';
                break;
              default:
                break;
            }

            ELEMENT_DATA.push(element);
          });
          this.dataSource = new MatTableDataSource<UserElement>(ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public doFilter = ($event: Event) => {
    this.dataSource.filter = ($event.target as HTMLInputElement).value
      .trim()
      .toLocaleLowerCase();
  };

  openOkCancelDialog(id: string) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          text: 'Voulez vous vraiment supprimer cet utilisateur !',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data === 'YES') {
          this.userService.deleteUser(id).subscribe(
            (res) => {
              if (res.data && res.data.deleteUser.id) {
                this.getUsers();
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      });
  }

  redirectToCreate(element?: UserElement) {
    if (element) {
      this.router.navigate(['users/update/', element.id]);
    } else {
      this.router.navigate(['users/new']);
    }
  }
}
