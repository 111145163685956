<mat-sidenav-container autosize>
  <mat-sidenav #sidenav [mode]="isLargeScreen ? 'side' : 'over'">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header></app-header>
    <article
      *ngIf="!isAdmin"
      class="text-xl flex items-center justify-center h-64"
    >
      <p *ngIf="!isAdmin">{{ noAccessMessage }}</p>
    </article>
    <article *ngIf="isAdmin">
      <router-outlet></router-outlet>
    </article>
  </mat-sidenav-content>
</mat-sidenav-container>
