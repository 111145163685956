import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from 'src/app/shared/services/category.service';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
  styleUrls: ['./update-category.component.scss'],
})
export class UpdateCategoryComponent implements OnInit {
  public categoryName!: string;
  private id!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    public categoryService: CategoryService
  ) {}

  ngOnInit() {
    this.categoryName = this.data.categoryName;
    this.id = this.data.id;
  }

  onUpdateCategory(): void {
    this.categoryService.updateCategory(this.id, this.categoryName).subscribe(
      (res) => {
        if (res.data && res.data.updateCategory.id) {
          this.dialogRef.close('UPDATED');
        }
      },
      (err) => {
        this.dialogRef.close('ERROR');
      }
    );
  }
}
