<div class="w-full h-full px-16 py-16">
  <div class="block sm:flex justify-center items-center">
    <p class="text-blue-400 text-lg m-0">Recherche:</p>
    <input
      placeholder="Tapez ici pour rechercher..."
      type="text"
      class="w-64 h-8 px-2 text-lg rounded-md outline-none sm:mx-4"
      (keyup)="doSearch($event)"
      placeholder=""
    />
  </div>
  <div class="block">
    <div class="text-blue-400 text-lg m-0 my-4 flex items-center">
      <mat-icon class="mat-18">filter_list_alt</mat-icon>Filtres
    </div>
    <form
      [formGroup]="filterForm"
      class="md:flex block items-center"
      novalidate
    >
      <p class="text-blue-400 text-lg m-0">Thématiques:</p>
      <mat-select
        class="
          md:w-1/4
          w-full
          h-8
          md:mx-2
          border-2
          rounded-lg
          outline-none
          px-2
          pt-1
          text-md
          bg-white
          border-blue-300
        "
        multiple
        formControlName="category"
        (selectionChange)="doFilterByCategory()"
      >
        <mat-option
          #catOption
          *ngFor="let category of categories"
          [value]="category.id"
          >{{ category.name }}</mat-option
        >
      </mat-select>
      <p class="text-blue-400 text-lg m-0">Filières:</p>
      <mat-select
        class="
          md:w-1/4
          w-full
          h-8
          md:mx-2
          border-2
          rounded-lg
          outline-none
          px-2
          pt-1
          text-md
          bg-white
          border-blue-300
        "
        multiple
        formControlName="faculty"
        (selectionChange)="doFilterByFaculty()"
      >
        <mat-option
          #facOption
          *ngFor="let faculty of faculties"
          [value]="faculty.id"
          >{{ faculty.title }}</mat-option
        >
      </mat-select>
    </form>
  </div>
  <div class="mat-elevation-z8 my-8">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="question_response">
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
        <td mat-cell *matCellDef="let element">{{ element.question }}</td>
      </ng-container>
      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Response</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHtml]="element.response"></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          Mettre à jour
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="primary"
            (click)="redirectToCreate(element)"
          >
            <mat-icon class="mat-18">system_update</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="text-center">Supprimer</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button
            mat-icon-button
            color="warn"
            (click)="openOkCancelDialog(element.id)"
          >
            <mat-icon class="mat-18">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Selectionner une page"
    >
    </mat-paginator>
  </div>
  <button mat-icon-button color="primary">
    <mat-icon (click)="redirectToCreate()" class="mat-18">add_circle</mat-icon>
  </button>
</div>
