import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/shared/services/notify.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { UserService } from 'src/app/shared/services/user.service';

export class User {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  gid: string = '';
  roleId: string = '';
}

export interface RoleElement {
  id: string;
  role: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  public userForm!: FormGroup;
  public isSubmitted: boolean = false;
  public currentUserId!: string;
  public roles: RoleElement[] = [];

  constructor(
    public userService: UserService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private roleService: RoleService,
    private notifyService: NotifyService
  ) {
    this.userForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.getRoles();
    this.activatedRoute.params.subscribe((v) => {
      if (v.id) {
        this.currentUserId = v.id;
        this.userService.getUser(this.currentUserId).subscribe(
          (res) => {
            if (res.data && res.data.getUser.id) {
              this.userFormControls.firstName.setValue(
                res.data.getUser.first_name
              );
              this.userFormControls.lastName.setValue(
                res.data.getUser.last_name
              );
              this.userFormControls.email.setValue(res.data.getUser.email);
              this.userFormControls.gid.setValue(res.data.getUser.gid);
              this.userFormControls.role.setValue(res.data.getUser.role.id);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  getRoles() {
    this.roleService.listUserRoles().subscribe(
      (res) => {
        if (res.data && res.data.listUserRoles) {
          res.data.listUserRoles.items.forEach((element: RoleElement) => {
            this.roles.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^[A-Za-z0-9._%+-]+@(engie.com|external.engie.com)$/
        ),
      ]),
      gid: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z]{2}[0-9]{4}$/),
      ]),
      role: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.userForm.valid) {
      const user = new User();
      user.firstName = this.userFormControls.firstName.value;
      user.lastName = this.userFormControls.lastName.value;
      user.email = this.userFormControls.email.value;
      user.gid = this.userFormControls.gid.value;
      user.roleId = this.userFormControls.role.value;
      if (this.currentUserId) {
        this.userService.updateUser(this.currentUserId, user).subscribe(
          (res) => {
            if (res.data && res.data.updateUser.id) {
              this.notifyService.emit('SUCCESS');
              this.router.navigate(['users']);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        this.userService.addUser(user).subscribe(
          (res) => {
            if (res.data && res.data.createUser.id) {
              this.notifyService.emit('SUCCESS');
              this.router.navigate(['users']);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  get userFormControls() {
    return this.userForm.controls;
  }
}
