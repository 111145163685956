import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(public http: HttpClient, public okta: OktaService) {}

  public addCategory(categoryName: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          createCategory(input: {name:"${categoryName}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public listCategories(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query{
          listCategories {items {id name }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public deleteCategory(id: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          deleteCategory(input:{id:"${id}"}){id faqs {
            items {
              id
            }
          }}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateCategory(id: string, name: string): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          updateCategory(input:{name:"${name}", id:"${id}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
