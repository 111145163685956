import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public activePage!: string;
  public isAdmin: boolean = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.hasAdminRole();
    this.handleMarkingSelectedMenu();
  }

  async hasAdminRole() {
    await this.authService.hasRoleAdmin();
    if (localStorage.getItem('USER_ROLE') === 'ADMIN') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  public redirectToCategories() {
    this.router.navigate(['/thematics']);
  }

  public redirectToFaculties() {
    this.router.navigate(['/faculties']);
  }

  public redirectToHomepageClient() {
    this.router.navigate(['/homepage/client']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  public redirectToUsers() {
    this.router.navigate(['/users']);
  }

  public redirectToQA() {
    this.router.navigate(['/questions/responses']);
  }

  public handleMarkingSelectedMenu() {
    this.route.url.subscribe(() => {
      this.highlightSelectedMenu(this.router.url);
    });

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.highlightSelectedMenu(data.state.url);
      }
    });
  }

  private highlightSelectedMenu(path: string): void {
    switch (path) {
      case '/thematics':
        this.activePage = 'thematics';
        break;
      case '/homepage/client':
        this.activePage = 'homepage-client';
        break;
      case '/users':
        this.activePage = 'users';
        break;
      case '/questions/responses':
        this.activePage = 'questionsResponses';
        break;
      case '/faculties':
        this.activePage = 'faculties';
        break;
      default:
        this.activePage = 'home';
        break;
    }
  }
}
