export const environment = {
  production: false,
  appsyncBaseUrl:
    'https://3tu6qtnrejc25o5qn6aof6eouu.appsync-api.eu-west-1.amazonaws.com/graphql',
  appsyncAapiKey: 'da2-243i2yw7sffofnadhzadhdvwzm',
  CLIENT_ID: '0oa1r7sjluLObfy1r0x7',
  ISSUER: 'https://engieapppreview.oktapreview.com',
  LOGIN_REDIRECT_URI:
    'https://stg.admin.vero.noprod.myfrren.com/login/callback',
  LOGOUT_REDIRECT_URI: 'https://stg.admin.vero.noprod.myfrren.com',
};
