import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CategoryElement } from '../categories/categories.component';
import { FacultyElement } from '../faculties/faculties.component';
import { DeleteDialogComponent } from '../shared/components/dialogs/delete-dialog/delete-dialog.component';
import { CategoryService } from '../shared/services/category.service';
import { FacultyService } from '../shared/services/faculty.service';
import { FaqCategoryService } from '../shared/services/faq-category.service';
import { FaqFacultyService } from '../shared/services/faq-faculty.service';
import { QuestionResponseService } from '../shared/services/question-response.service';



@Component({
  selector: 'app-question-response',
  templateUrl: './question-response.component.html',
  styleUrls: ['./question-response.component.scss'],
})
export class QuestionResponseComponent implements OnInit {
  public categoryName!: string;
  public displayedColumns: string[] = [
    'question',
    'response',
    'update',
    'delete',
  ];
  public categories: CategoryElement[] = [];
  public faculties: FacultyElement[] = [];
  public ELEMENT_DATA: any[] = [];
  public dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public filterForm!: FormGroup;

  constructor(
    private questionResponseService: QuestionResponseService,
    private categoryService: CategoryService,
    private facultyService: FacultyService,
    public faqFacultyService: FaqFacultyService,
    public faqCategoryService: FaqCategoryService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.filterForm = this.createFormGroup();
    this.getFAQS();
    this.getCategories();
    this.getFaculties();
  }

  createFormGroup() {
    return new FormGroup({
      faculty: new FormControl(''),
      category: new FormControl(''),
    });
  }

  getFAQS(): void {
    this.questionResponseService.listFAQS().subscribe(
      (res) => {
        if (res.data && res.data.listFAQS) {
          this.ELEMENT_DATA = [];
          res.data.listFAQS.items.forEach((element: any) => {
            const elementCategories = element.categories.items.map(function (
              cat: any
            ) {
              return cat.category.id;
            });
            const elementFaculties = element.faculties.items.map(function (
              fac: any
            ) {
              return fac.faculty.id;
            });
            const customElement = element;
            customElement.categories = elementCategories;
            customElement.faculties = elementFaculties;
            try {
              customElement.response = decodeURI(element.response);
            } catch (error) {}
            this.ELEMENT_DATA.push(customElement);
          });
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        console.log(this.ELEMENT_DATA)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCategories(): void {
    this.categoryService.listCategories().subscribe(
      (res) => {
        if (res.data && res.data.listCategories) {
          res.data.listCategories.items.forEach((element: any) => {
            this.categories.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getFaculties(): void {
    this.facultyService.listFaculties().subscribe(
      (res) => {
        if (res.data && res.data.listFaculties) {
          res.data.listFaculties.items.forEach((element: any) => {
            this.faculties.push(element);
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public doSearch = ($event: Event) => {
    this.dataSource.filter = ($event.target as HTMLInputElement).value
      .trim()
      .toLocaleLowerCase();
  };

  public doFilterByCategory = () => {
    const selectedCategoriesIds = this.formCategory!.value;
    if (selectedCategoriesIds.length > 0) {
      let FILTERED_ELEMENT_DATA: any[] = [];
      this.ELEMENT_DATA.forEach((element) => {
        for(let cat of element.categories) {
          if (selectedCategoriesIds.indexOf(cat) !== -1) {
            FILTERED_ELEMENT_DATA.push(element);
            break;
          }
        }
      });
      this.dataSource = new MatTableDataSource<any>(FILTERED_ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  };

  public doFilterByFaculty = () => {
    const selectedFacultiesIds = this.formFaculty!.value;
    if (selectedFacultiesIds.length > 0) {
      let FILTERED_ELEMENT_DATA: any[] = [];
      this.ELEMENT_DATA.forEach((element) => {
        for (let fac of element.faculties) {
          if (selectedFacultiesIds.indexOf(fac) !== -1) {
            FILTERED_ELEMENT_DATA.push(element);
            break;
          }
        }
      });
      this.dataSource = new MatTableDataSource<any>(FILTERED_ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  };

  get formCategory() {
    return this.filterForm.get('category');
  }
  get formFaculty() {
    return this.filterForm.get('faculty');
  }

  openOkCancelDialog(id: string) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          text: 'Voulez vous vraiment supprimer cette question / réponse !',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data === 'YES') {
          this.questionResponseService.deleteFAQ(id).subscribe(
            (res) => {
              if (res.data && res.data.deleteFAQ.id) {
                res.data.deleteFAQ.faculties.items.forEach(
                  (faqFaculty: { faculty: { id: string } }) => {
                    this.faqFacultyService
                      .deleteFAQFaculty(faqFaculty.faculty.id)
                      .subscribe(
                        (res2) => {
                          console.log(res2);
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  }
                );
                res.data.deleteFAQ.categories.items.forEach(
                  (faqCategory: { category: { id: string } }) => {
                    this.faqCategoryService
                      .deleteFAQCategory(faqCategory.category.id)
                      .subscribe(
                        (res3) => {
                          console.log(res3);
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  }
                );
                this.getFAQS();
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      });
  }

  redirectToCreate(element?: any) {
    if (element) {
      this.router.navigate(['questions/responses/update/', element.id]);
    } else {
      this.router.navigate(['questions/responses/new']);
    }
  }
}
