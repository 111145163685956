import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteDialogComponent } from 'src/app/shared/components/dialogs/delete-dialog/delete-dialog.component';
import { CategoryService } from 'src/app/shared/services/category.service';
import { FacultyService } from 'src/app/shared/services/faculty.service';
import { NotifyService } from 'src/app/shared/services/notify.service';

export interface CategoryElement {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-faculty',
  templateUrl: './create-faculty.component.html',
  styleUrls: ['./create-faculty.component.scss'],
})
export class CreateFacultyComponent implements OnInit {
  public modules = {};
  public currentFacultyId!: string;
  public categories: CategoryElement[] = [];
  public faculty = {
    title: {
      value: '',
      hasError: false,
    },
    categoryId: {
      value: '',
      hasError: false,
    },
    content: {
      value: '',
    },
  };

  constructor(
    public categoryService: CategoryService,
    public facultyService: FacultyService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private notifyService: NotifyService
  ) {
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['link', 'image'],
      ],
    };
  }

  ngOnInit(): void {
    this.faculty.title.hasError = false;
    this.faculty.categoryId.hasError = false;

    this.categoryService.listCategories().subscribe(
      (res) => {
        if (res.data && res.data.listCategories) {
          this.categories = [];
          res.data.listCategories.items.forEach((element: any) => {
            this.categories.push(element);
          });
        }
        this.activatedRoute.params.subscribe((v) => {
          if (v.id) {
            this.currentFacultyId = v.id;
            this.facultyService.getFaculty(this.currentFacultyId).subscribe(
              (res) => {
                if (res.data && res.data.getFaculty.id) {
                  this.faculty.title.value = res.data.getFaculty.title;
                  this.faculty.content.value = decodeURI(
                    res.data.getFaculty.content
                  );
                  this.faculty.categoryId.value =
                    res.data.getFaculty.category.id;
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onChange($event: any) {
    this.faculty.categoryId.hasError = false;
    this.faculty.categoryId.value = $event.value;
  }

  onSave() {
    if (this.isFormValid()) {
      if (
        this.faculty.content.value == '' ||
        this.faculty.content.value == null
      ) {
        this.dialog
          .open(DeleteDialogComponent, {
            data: {
              text: 'Voulez vous enregistrer cette filière sans contenu ?',
            },
          })
          .afterClosed()
          .subscribe((data) => {
            if (data === 'YES') {
              if (this.currentFacultyId) {
                this.facultyService
                  .updateFaculty(this.currentFacultyId, this.faculty)
                  .subscribe(
                    (res) => {
                      if (res.data && res.data.updateFaculty.id) {
                        this.notifyService.emit('SUCCESS');
                        this.router.navigate(['/faculties']);
                      }
                    },
                    (err) => {
                      console.log(err);
                    }
                  );
              } else {
                this.facultyService.addFaculty(this.faculty).subscribe(
                  (res) => {
                    if (res.data && res.data.createFaculty.id) {
                      this.notifyService.emit('SUCCESS');
                      this.router.navigate(['/faculties']);
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
            }
          });
      } else {
        if (this.currentFacultyId) {
          this.facultyService
            .updateFaculty(this.currentFacultyId, this.faculty)
            .subscribe(
              (res) => {
                if (res.data && res.data.updateFaculty.id) {
                  this.notifyService.emit('SUCCESS');
                  this.router.navigate(['/faculties']);
                }
              },
              (err) => {
                console.log(err);
              }
            );
        } else {
          this.facultyService.addFaculty(this.faculty).subscribe(
            (res) => {
              if (res.data && res.data.createFaculty.id) {
                this.notifyService.emit('SUCCESS');
                this.router.navigate(['/faculties']);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      }
    }
  }

  clearTitleError() {
    this.faculty.title.hasError = false;
  }

  isFormValid(): boolean {
    this.faculty.title.hasError = true;
    this.faculty.categoryId.hasError = true;

    if(this.faculty.title.value && this.faculty.title.value != '') this.faculty.title.hasError = false;
    if(this.faculty.categoryId.value && this.faculty.categoryId.value != '') this.faculty.categoryId.hasError = false
    if (
      this.faculty.title.hasError && this.faculty.categoryId.hasError
    ) {
      return false;
    }

    return true;
  }
}
