import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from './okta.service';

@Injectable({
  providedIn: 'root',
})
export class HomepageClientService {
  constructor(public http: HttpClient, public okta: OktaService) {}

  public listHomepageTexts(): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.appsyncAapiKey,
    });
    const body = {
      query: `
        query {
          listHomepageTexts {
            items {
              id
              primary
              facultiesText
            }
          }
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public updateHomepageTexts(id: string, homepageTexts: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          updateHomepageTexts(input: {id:"${id}", primary:"${homepageTexts.primaryText}", facultiesText:"${homepageTexts.facultiesText}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }

  public createHomepageTexts(homepageTexts: any): Observable<any> {
    const oktaToken = this.okta.getIdToken();
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: oktaToken ? oktaToken : '',
    });
    const body = {
      query: `
        mutation{
          createHomepageTexts(input: { primary:"${homepageTexts.primaryText}", facultiesText:"${homepageTexts.facultiesText}"}){id}
        }
      `,
    };
    return this.http.post(environment.appsyncBaseUrl, body, {
      headers: header,
    });
  }
}
