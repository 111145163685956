<div class="header flex items-center mb-2 border-b-2 border-white">
  <div class="cursor-pointer mx-auto">
    <img src="assets/svg/logo-white.svg" (click)="goToHome()" alt="image"/>
  </div>
</div>

<mat-nav-list *ngIf="isAdmin">
  <mat-list-item
    (click)="redirectToHomepageClient()"
    [ngClass]="{ active: activePage == 'homepage-client' }"
    ><mat-icon>home</mat-icon>Homepage client</mat-list-item
  >
  <mat-list-item
    (click)="redirectToFaculties()"
    [ngClass]="{ active: activePage == 'faculties' }"
    ><mat-icon>work</mat-icon>Filières / Articles</mat-list-item
  >
  <mat-list-item
    (click)="redirectToQA()"
    [ngClass]="{ active: activePage == 'questionsResponses' }"
    ><mat-icon>question_answer</mat-icon>Q&A</mat-list-item
  >
  <mat-list-item
    (click)="redirectToCategories()"
    [ngClass]="{ active: activePage == 'thematics' }"
    ><mat-icon>category</mat-icon>Thématiques</mat-list-item
  >
  <mat-list-item
    (click)="redirectToUsers()"
    [ngClass]="{ active: activePage == 'users' }"
    ><mat-icon>people</mat-icon>Utilisateurs</mat-list-item
  >
</mat-nav-list>
