import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { OktaService } from '../shared/services/okta.service';

@Injectable({
  providedIn: 'root',
})
export class OktaOauthGuard implements CanActivate {
  constructor(private okta: OktaService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = await this.okta.isAuthenticated();
    if (authenticated) {
      return true;
    }

    // Redirect to login flow.
    this.okta.login(state.url);
    return false;
  }
}
