<h2 mat-dialog-title>Mise à jour thématique</h2>
<div mat-dialog-content>
  <input
    type="text"
    placeholder="Nom de la catégorie"
    [(ngModel)]="categoryName"
    class="
      w-64
      h-8
      px-2
      text-lg
      rounded-md
      outline-none
      sm:mx-4
      my-4
      sm:my-0
      bg-gray-100
    "
  />
</div>
<div mat-dialog-actions class="text-center">
  <button
    (click)="onUpdateCategory()"
    class="
      bg-blue-300
      hover:bg-blue-400
      text-white
      font-bold
      py-2
      px-4
      mx-2
      rounded
      inline-flex
      items-center
    "
  >
    <span>Sauvegarder</span>
  </button>
</div>
