<h2 mat-dialog-title>Suppression</h2>
<div mat-dialog-content>
  <p>{{ text }}</p>
</div>
<div mat-dialog-actions class="text-center">
  <button
    (click)="onYesClick()"
    class="
      bg-blue-300
      hover:bg-blue-400
      text-white
      font-bold
      py-2
      px-4
      mx-2
      rounded
      inline-flex
      items-center
    "
  >
    <span>Oui</span>
  </button>
  <button
    (click)="onNoClick()"
    class="
      bg-blue-300
      hover:bg-blue-400
      text-white
      font-bold
      py-2
      px-4
      mx-2
      rounded
      inline-flex
      items-center
    "
  >
    <span>Non</span>
  </button>
</div>
