import { Injectable } from '@angular/core';
import { OktaService } from './okta.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public okta: OktaService, private userService: UserService) {}

  async hasRoleAdmin() {
    // Checks if there is a current accessToken in the TokenManger.
    let user = await this.okta.getUser()
    const currentUserEmail = user?.email?.toLowerCase();
    await this.userService.setUserRole(currentUserEmail);
  }
}
