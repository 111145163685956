import { CategoryElement } from './../../categories/categories.component';
import { FaqFacultyService } from './../../shared/services/faq-faculty.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FacultyElement } from 'src/app/faculties/faculties.component';
import { FacultyService } from 'src/app/shared/services/faculty.service';
import { QuestionResponseService } from 'src/app/shared/services/question-response.service';
import { FaqCategoryService } from 'src/app/shared/services/faq-category.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { NotifyService } from 'src/app/shared/services/notify.service';

export class QuestionResponse {
  question: string = '';
  response: string = '';
  facultiesIds: string[] = [];
  categoriesIds: string[] = [];
}

@Component({
  selector: 'app-add-question-response',
  templateUrl: './add-question-response.component.html',
  styleUrls: ['./add-question-response.component.scss'],
})
export class AddQuestionResponseComponent implements OnInit {
  public qaForm!: FormGroup;
  public faculties: FacultyElement[] = [];
  public categories: CategoryElement[] = [];
  public isSubmitted: boolean = false;
  public currentFAQId!: string;
  public faqFacultyIdsToDelete: string[] = [];
  public faqCategoryIdsToDelete: string[] = [];
  public modules = {};

  constructor(
    public facultyService: FacultyService,
    public categoryService: CategoryService,
    public questionResponseService: QuestionResponseService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public faqFacultyService: FaqFacultyService,
    public faqCategoryService: FaqCategoryService,
    private notifyService: NotifyService
  ) {
    this.modules = {
      toolbar: [['bold', 'italic', 'underline', 'strike']],
    };
    this.qaForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.facultyService.listFaculties().subscribe(
      (res) => {
        if (res.data && res.data.listFaculties) {
          res.data.listFaculties.items.forEach((element: any) => {
            this.faculties.push(element);
          });
          this.categoryService.listCategories().subscribe(
            (res2) => {
              if (res2.data && res2.data.listCategories) {
                res2.data.listCategories.items.forEach((element: any) => {
                  this.categories.push(element);
                });
                this.activatedRoute.params.subscribe((v) => {
                  if (v.id) {
                    this.currentFAQId = v.id;
                    this.questionResponseService
                      .getFAQ(this.currentFAQId)
                      .subscribe(
                        (res) => {
                          if (res.data && res.data.getFAQ.id) {
                            this.qaFormControls.question.setValue(
                              res.data.getFAQ.question
                            );
                            try {
                              this.qaFormControls.response.setValue(
                                decodeURI(res.data.getFAQ.response)
                              );
                            } catch (error) {
                              this.qaFormControls.response.setValue(
                                res.data.getFAQ.response
                              );
                            }

                            let facultiesIds: string[] = [];
                            res.data.getFAQ.faculties.items.forEach(
                              (item: {
                                id: string;
                                faculty: { id: string };
                              }) => {
                                facultiesIds.push(item.faculty.id);
                                this.faqFacultyIdsToDelete.push(item.id);
                              }
                            );
                            this.qaFormControls.faculty.setValue(facultiesIds);
                            let categoriesIds: string[] = [];
                            res.data.getFAQ.categories.items.forEach(
                              (item: {
                                id: string;
                                category: { id: string };
                              }) => {
                                categoriesIds.push(item.category.id);
                                this.faqCategoryIdsToDelete.push(item.id);
                              }
                            );
                            this.qaFormControls.category.setValue(
                              categoriesIds
                            );
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  }
                });
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  createFormGroup() {
    return new FormGroup({
      question: new FormControl('', Validators.required),
      response: new FormControl('', Validators.required),
      faculty: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.qaForm.valid) {
      const questionResponse = new QuestionResponse();
      questionResponse.question = this.qaFormControls.question.value;
      questionResponse.response = this.qaFormControls.response.value;
      questionResponse.facultiesIds = this.qaFormControls.faculty.value;
      questionResponse.categoriesIds = this.qaFormControls.category.value;
      if (this.currentFAQId) {
        this.questionResponseService
          .updateFAQ(this.currentFAQId, questionResponse)
          .subscribe(
            (res) => {
              if (res.data && res.data.updateFAQ.id) {
                questionResponse.facultiesIds.forEach((facultyId) => {
                  this.faqFacultyService
                    .addFAQFaculty(res.data.updateFAQ.id, facultyId)
                    .subscribe(
                      (res2) => {},
                      (err) => {
                        console.log(err);
                      }
                    );
                });
                questionResponse.categoriesIds.forEach((categoryId) => {
                  this.faqCategoryService
                    .addFAQCategory(res.data.updateFAQ.id, categoryId)
                    .subscribe(
                      (res2) => {},
                      (err) => {
                        console.log(err);
                      }
                    );
                });
                this.faqFacultyIdsToDelete.forEach((faqFacultyId) => {
                  this.faqFacultyService
                    .deleteFAQFaculty(faqFacultyId)
                    .subscribe(
                      (res3) => {},
                      (err) => {
                        console.log(err);
                      }
                    );
                });
                this.faqCategoryIdsToDelete.forEach((faqCategoryId) => {
                  this.faqCategoryService
                    .deleteFAQCategory(faqCategoryId)
                    .subscribe(
                      (res3) => {},
                      (err) => {
                        console.log(err);
                      }
                    );
                });
                this.notifyService.emit('SUCCESS');
                this.router.navigate(['questions/responses']);
              }
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this.questionResponseService.addFAQ(questionResponse).subscribe(
          (res) => {
            if (res.data && res.data.createFAQ.id) {
              questionResponse.facultiesIds.forEach((facultyId) => {
                this.faqFacultyService
                  .addFAQFaculty(res.data.createFAQ.id, facultyId)
                  .subscribe(
                    (res2) => {},
                    (err) => {
                      console.log(err);
                    }
                  );
              });
              questionResponse.categoriesIds.forEach((categoryId) => {
                this.faqCategoryService
                  .addFAQCategory(res.data.createFAQ.id, categoryId)
                  .subscribe(
                    (res2) => {},
                    (err) => {
                      console.log(err);
                    }
                  );
              });
              this.notifyService.emit('SUCCESS');
              this.router.navigate(['questions/responses']);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  onSelectedFaculty(e: any) {
    this.qaFormControls.faculty.setValue(e?.target.value, {
      onlySelf: true,
    });
  }

  get qaFormControls() {
    return this.qaForm.controls;
  }
}
