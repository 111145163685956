<div class="w-full h-full px-16 py-16">
  <div class="w-full md:w-1/2 lg:w-1/3 mx-auto my-8">
    <p class="w-full text-center text-xl">Création d'utilisateur</p>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="firstName">Prénom: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <input
          placeholder="Prénom"
          formControlName="firstName"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('firstName')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && userFormControls.firstName.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Le prénom est requis.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="lastName">Nom: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <input
          placeholder="Nom"
          formControlName="lastName"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('lastName')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && userFormControls.lastName.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Le nom est requis.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="email">Email: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <input
          placeholder="Email"
          formControlName="email"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('email')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && userFormControls.email.errors?.required"
        class="text-red-500 text-xs italic"
      >
        L'adresse email est requise.
      </p>
      <p
        *ngIf="isSubmitted && userFormControls.email.errors?.pattern"
        class="text-red-500 text-xs italic"
      >
        L'adresse email n'est pas valide (le domaine doit être @engie.com ou
        @external.engie.com).
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="gid">GID: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <input
          placeholder="Group Id"  
          formControlName="gid"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && userForm.get('gid')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && userFormControls.gid.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Le GID est requis.
      </p>
      <p
        *ngIf="isSubmitted && userFormControls.gid.errors?.pattern"
        class="text-red-500 text-xs italic"
      >
        Le GID n'est pas valide.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="role">Rôle: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <mat-select
          formControlName="role"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md pt-1"
          [ngClass]="
            isSubmitted && userFormControls.role.errors?.required
              ? 'border-red-300'
              : 'border-blue-300'
          "
        >
          <mat-option *ngFor="let role of roles" [value]="role.id">{{
            role.role
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && userFormControls.role.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Le rôle est requis.
      </p>
    </div>

    <div class="w-1/3 mx-auto my-8 flex justify-center xl:block">
      <button
        class="
          bg-blue-300
          hover:bg-blue-400
          text-white
          font-bold
          py-2
          px-4
          rounded
          inline-flex
          items-center
        "
        type="submit"
      >
        Sauvegarder
      </button>
    </div>
  </form>
</div>
