<div class="w-full h-full px-16 py-16">
  <div class="w-full md:w-1/2 lg:w-1/3 mx-auto my-8">
    <p class="w-full text-center text-xl">Ajouter une question / réponse</p>
  </div>

  <form [formGroup]="qaForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="question">Question: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <input
          placeholder="Question"
          formControlName="question"
          class="w-full h-8 border-2 rounded-lg outline-none px-2 text-md"
          [ngClass]="
            isSubmitted && qaForm.get('question')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && qaFormControls.question.errors?.required"
        class="text-red-500 text-xs italic"
      >
        La champ question est requis.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="response">Réponse: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <quill-editor
          class="w-full"
          placeholder="Editer le contenu"
          formControlName="response"
          [modules]="modules"
        ></quill-editor>
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-16">
      <p
        *ngIf="isSubmitted && qaFormControls.response.errors?.required"
        class="text-red-500 text-xs italic"
      >
        La champ réponse est requis.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="faculty">Filières: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <mat-select
          class="
            w-full
            h-8
            border-2
            rounded-lg
            outline-none
            px-2
            pt-1
            text-md
            bg-white
          "
          [ngClass]="
            isSubmitted && qaForm.get('faculty')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
          formControlName="faculty"
          multiple
        >
          <mat-option *ngFor="let faculty of faculties" [value]="faculty.id">{{
            faculty.title
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && qaFormControls.faculty.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Vauillez choisir une / des facultés.
      </p>
    </div>

    <div class="w-full xl:w-1/3 mx-auto block xl:flex items-center">
      <div
        class="w-full md:w-1/2 md:mx-auto flex xl:justify-start mb-2 xl:mb-0"
      >
        <label for="faculty">Thématiques: </label>
      </div>
      <div class="w-full md:w-1/2 md:mx-auto flex justify-start">
        <mat-select
          class="
            w-full
            h-8
            border-2
            rounded-lg
            outline-none
            px-2
            pt-1
            text-md
            bg-white
          "
          [ngClass]="
            isSubmitted && qaForm.get('category')?.invalid
              ? 'border-red-300'
              : 'border-blue-300'
          "
          formControlName="category"
          multiple
        >
          <mat-option
            *ngFor="let category of categories"
            [value]="category.id"
            >{{ category.name }}</mat-option
          >
        </mat-select>
      </div>
    </div>
    <div class="w-full md:w-1/2 xl:w-1/3 md:mx-auto my-2 xl:mb-0 h-10">
      <p
        *ngIf="isSubmitted && qaFormControls.category.errors?.required"
        class="text-red-500 text-xs italic"
      >
        Vauillez choisir une / des thématiques.
      </p>
    </div>

    <div class="w-1/3 mx-auto my-8 flex justify-center xl:block">
      <button
        class="
          bg-blue-300
          hover:bg-blue-400
          text-white
          font-bold
          py-2
          px-4
          rounded
          inline-flex
          items-center
        "
        type="submit"
      >
        Sauvegarder
      </button>
    </div>
  </form>
</div>
