import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  public text = 'Voulez vraiment supprimer cet element';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {}

  ngOnInit() {
    this.text = this.data.text;
  }

  onNoClick(): void {
    this.dialogRef.close('NO');
  }

  onYesClick(): void {
    this.dialogRef.close('YES');
  }
}
