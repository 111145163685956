import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material-module';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './layout/menu/menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { CategoriesComponent } from './categories/categories.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphQLModule } from './shared/modules/graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './shared/components/custom-paginator-configuration';
import { UpdateCategoryComponent } from './shared/components/modals/update-category/update-category.component';
import { UsersComponent } from './users/users.component';
import { CreateComponent } from './users/create/create.component';
import { FacultiesComponent } from './faculties/faculties.component';
import { CreateFacultyComponent } from './faculties/create-faculty/create-faculty.component';
import { QuillModule } from 'ngx-quill';
import { QuestionResponseComponent } from './question-response/question-response.component';
import { AddQuestionResponseComponent } from './question-response/add-question-response/add-question-response.component';
import { HomepageClientComponent } from './homepage-client/homepage-client.component';
import { CallbackComponent } from './callback/callback.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    HeaderComponent,
    CategoriesComponent,
    UpdateCategoryComponent,
    UsersComponent,
    CreateComponent,
    FacultiesComponent,
    CreateFacultyComponent,
    QuestionResponseComponent,
    AddQuestionResponseComponent,
    HomepageClientComponent,
    CallbackComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    GraphQLModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
  bootstrap: [AppComponent],
})
export class AppModule {}
