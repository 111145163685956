import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OktaService } from 'src/app/shared/services/okta.service';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private sidenavService: SidenavService,
    public router: Router,
    public okta: OktaService
  ) {}

  clickMenu() {
    this.sidenavService.toggle();
  }
}
