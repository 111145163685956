import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogComponent } from '../shared/components/dialogs/delete-dialog/delete-dialog.component';
import { UpdateCategoryComponent } from '../shared/components/modals/update-category/update-category.component';
import { CategoryService } from '../shared/services/category.service';
import { FaqCategoryService } from '../shared/services/faq-category.service';
import { NotifyService } from '../shared/services/notify.service';

export interface CategoryElement {
  id: string;
  name: string;
}



@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, AfterViewInit {
  public categoryName!: string;
  public displayedColumns: string[] = ['name', 'update', 'delete'];
  public ELEMENT_DATA: CategoryElement[] = [];
  public dataSource = new MatTableDataSource<CategoryElement>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private categoryService: CategoryService,
    public faqCategoryService: FaqCategoryService,
    public dialog: MatDialog,
    private notifyService: NotifyService
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(): void {
    this.categoryService.listCategories().subscribe(
      (res) => {
        if (res.data && res.data.listCategories) {
          this.ELEMENT_DATA = [];
          res.data.listCategories.items.forEach((element: any) => {
            this.ELEMENT_DATA.push(element);
          });
          this.dataSource = new MatTableDataSource<CategoryElement>(
            this.ELEMENT_DATA
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public doFilter = ($event: Event) => {
    this.dataSource.filter = ($event.target as HTMLInputElement).value
      .trim()
      .toLocaleLowerCase();
  };

  public addCategory() {
    this.categoryService.addCategory(this.categoryName).subscribe(
      (res) => {
        if (res.data && res.data.createCategory.id) {
          this.notifyService.emit('SUCCESS');
          this.getCategories();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openOkCancelDialog(id: string) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          text: 'Voulez vous vraiment supprimer cette thématique !',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data === 'YES') {
          this.categoryService.deleteCategory(id).subscribe(
            (res) => {
              if (res.data && res.data.deleteCategory.id) {
                res.data.deleteCategory.faqs.items.forEach(
                  (faqCategory: { id: string }) => {
                    this.faqCategoryService
                      .deleteFAQCategory(faqCategory.id)
                      .subscribe(
                        (res2) => {
                          this.notifyService.emit('SUCCESS');
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                  }
                );
                this.getCategories();
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      });
  }

  openUpdateCategoryModal(category: CategoryElement) {
    this.dialog
      .open(UpdateCategoryComponent, {
        data: {
          categoryName: category.name,
          id: category.id,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data === 'UPDATED') {
          this.getCategories();
        }
      });
  }
}
